import React from "react";
import styled from "styled-components";
import { withTheme } from "@callstack/react-theme-provider";

const Header = (props) => (
    <React.Fragment>
        <Container 
            backgroundColor={props.theme.headerBackgroundColor}
            backgroundColorGradient={props.theme.headerBackgroundColorGradient}
            foreColor={props.theme.headerForeColor}>
            <div className="header__elements">
                <img className="logo" src={props.theme.logo} alt={props.theme.headerTitle} />
                <div>{props.theme.headerTitle}</div>
            </div>
        </Container>
        {props.children}
    </React.Fragment>
);

const Container = styled.header`
    background: ${props => `linear-gradient(to right, ${props.backgroundColor}, ${props.backgroundColorGradient});`};
    color: ${props => props.foreColor};
`;

export default withTheme(Header);