import React from 'react';
import {StyledButton} from '../../styled';
import {withTheme} from '@callstack/react-theme-provider';

class CancelButton extends React.Component {
    render() {
        return <StyledButton
                    variant={this.props.theme.buttonVariant}
                    color={this.props.theme.cancelColor}
                    className={this.props.className}
                    text={this.props.title}
                    onClick={this.props.onClick}
                    disabled={this.props.disabled}
                    ignoreDisabledBorder={true} />
    }
}

export default withTheme(CancelButton);