import variables from './sass/_variables.scss';
import logo from './assets/logo.png';

export const themes = {
    light: {
      spinnerColor: variables.primaryColor,
      buttonVariant: "outlined",
      successColor: "#32CD32",
      infoColor: "#191970",
      errorColor: "#d32f2f",
      warningColor: "#ffa000",
      submitColor: "#3f51b5",
      cancelColor: "#000",
      headerTitle: "Ticket Portal",
      headerBackgroundColor: variables.primaryColor,
      headerBackgroundColorGradient: variables.secondaryColor,
      headerForeColor: variables.noColor,
      mainBackgroundColor: variables.bodyBackgroundColor,
      mainForeColor: variables.fontColor,
      footerBackgroundColor: variables.primaryColor,
      footerBackgroundColorGradient: variables.secondaryColor,
      footerForeColor: '#8f9fa4',
      footerForeColorHover: "#000",
      footerCopyrightBorderColor: "#F5F5F5",
      linkColor: variables.linkColor,
      linkHover: variables.linkColorHover,
      logo,
      ticketHeader: {
        borderColor: variables.primaryColor,
        backgroundColor: variables.primaryBackgroundColor,
        titleColor: variables.primaryColor,
        button: {
          backgroundColor: variables.noColor,
          backgroundColorHover: variables.primaryBackgroundColor
        }
      },
      ticketHistoryItemAgent: {
        "backgroundColor": "#fff",
        "borderColor": "#C0C0C0",
        "fontColor": "#06363d"
      },
      ticketHistoryItemCustomer: {
          "backgroundColor": "#fff",
          "borderColor": "#455575",
          "fontColor": "#455575"
      }
    },
    dark: {
      spinnerColor: variables.secondaryColor,
      buttonVariant: "outlined",
      successColor: "#32CD32",
      infoColor: "#191970",
      errorColor: "#d32f2f",
      warningColor: "#ffa000",
      submitColor: "#3f51b5",
      cancelColor: "#000",
      headerTitle: "Ticket Portal",
      headerBackgroundColor: variables.secondaryColor,
      headerBackgroundColorGradient: variables.primaryColor,
      headerForeColor: variables.noColor,
      mainBackgroundColor: variables.bodyBackgroundColor,
      mainForeColor: variables.fontColor,
      footerBackgroundColor: variables.secondaryColor,
      footerBackgroundColorGradient: variables.primaryColor,
      footerForeColor: '#8f9fa4',
      footerForeColorHover: "#000",
      footerCopyrightBorderColor: "#F5F5F5",
      linkColor: variables.linkColorHover,
      linkHover: variables.linkColor,
      logo: logo,
      ticketHeader: {
        borderColor: variables.secondaryColor,
        backgroundColor: variables.secondaryBackgroundColor,
        titleColor: variables.secondaryColor,
        button: {
          backgroundColor: variables.primaryBackgroundColor,
          backgroundColorHover: variables.secondaryBackgroundColor
        }
      },
      ticketHistoryItemAgent: {
        "backgroundColor": "#fff",
        "borderColor": "#C0C0C0",
        "fontColor": "#06363d"
      },
      ticketHistoryItemCustomer: {
          "backgroundColor": "#fff",
          "borderColor": "#455575",
          "fontColor": "#455575"
      }
    }
  };