import React from 'react';
import {StyledLink} from '../components/styled';

export default class EnvironmentVariables extends React.Component {
    render() {
        return <React.Fragment>
            <div>
                <StyledLink to="/" text="Back to Home" />
            </div>
            <div>
                <small>You are running this application in <strong>{process.env.NODE_ENV}</strong> mode.</small>
            </div>
            <div>
                <small>Api Url is <strong>{process.env.REACT_APP_API_URL}</strong></small>
            </div>
        </React.Fragment>
    }
}