import React from 'react';
import FormContainer from '../form/FormContainer';
import RichTextEditor from '../form/RichTextEditor';
import { buildRule, FormValidator } from '../form/FormValidator';
import PropTypes from 'prop-types';
import {withTheme} from '@callstack/react-theme-provider';
import { translate } from "react-i18next";
import validator from 'validator';

class AddComment extends React.Component {
    state = {
        submitDisabled: true
    };

    static getDerivedStateFromProps(props) {
        return {
            submitDisabled: validator.isEmpty(props.value, {ignore_whitespace: true})
        }
    }

    render() {
        return <React.Fragment>
            <FormContainer
                submitTitle={this.props.t('add-comment.submitTitle')} 
                cancelTitle={this.props.t('add-comment.cancelTitle')}
                onSubmit={this.props.onSubmit}
                onCancel={this.props.onCancel}
                submitDisabled={this.state.submitDisabled}>

                <RichTextEditor 
                    value={this.props.value} 
                    onChange={this.handleOnChange} />
                
            </FormContainer>
        </React.Fragment>
    }

    handleOnChange = (value) => {        
        const commentRule = buildRule('comment', 'isEmpty', [{ignore_whitespace: true}], false, 'Please enter a comment');

        const validator = new FormValidator([commentRule]);

        const validation = validator.validate({comment: value.text});

        if (validation.isValid) {
            this.setState({submitDisabled: false});
        } else {
            this.setState({submitDisabled: true});
        }

        this.props.onChange(value);
    }
}

AddComment.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

export default translate("ticket")(withTheme(AddComment));