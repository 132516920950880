import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import dotenv from 'dotenv';
import dotenvExpand  from 'dotenv-expand';
import I18nextConfiguration from "./components/i18n";
import { ThemeProvider } from "@callstack/react-theme-provider";
import {Header, Main, Footer} from './components/layout';
import {Helmet} from "react-helmet";
import TicketPortalService from './services/TicketPortalService';
import {themes} from './themes'
import {PageLoading} from './components/spinners';
import CookieService from './services/CookieService';
import GAService from './services/GAService';

const myEnv = dotenv.config();
dotenvExpand(myEnv);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }
    
    props.history.listen(location => {
      window.scrollTo(0, 0);
      GAService.pageView(location.pathname);
    });
  }

  static getDerivedStateFromProps(props) {
    if (props.theme && props.customizationsPath) {
      return {loading: false};
    }
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Ticker Portal</title>
          <meta name="description" content="Ticket portal"></meta>
        </Helmet>
        <I18nextConfiguration configurationsPath={this.props.customizationsPath}>
          <ThemeProvider theme={this.props.theme}>
            <PageLoading loading={this.state.loading} />
            <Header />
            <Main>
              {this.props.children}
            </Main>
            <Footer/>
          </ThemeProvider>
        </I18nextConfiguration>
      </React.Fragment>
    );
  }
}

const withCustomizations = (WrappedComponent) => {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        path: '',
        theme: {}
      };
    }

    getWrappedComponent = () => {
      if (this.state.path && this.state.theme) {
        return <WrappedComponent 
                customizationsPath={this.state.path}
                theme={this.state.theme}
                {...this.props} />
      }
    }

    render() {
      return <React.Fragment>
        {this.getWrappedComponent()}
      </React.Fragment>
    }

    async componentDidMount() { 
      try {
        const path = await this.getCustomizationsPath();
    
        const theme = await TicketPortalService.getJSON(`${path}/theme.json`);
    
        this.setState({path, theme});
      } catch {
        this.setState({path: process.env.REACT_APP_CONFIG_URL, theme: themes.light});
      }

      CookieService.showCookiebot();
    }
  
    getCustomizationsPath = async () => {
      let orgName = 'myreadreceipts';
  
      const routePaths = window.location.pathname.split('/');
  
      if (routePaths.length >= 3 && routePaths[3]) {
        orgName = routePaths[3];
      }
  
      return await TicketPortalService.getCustomizations(orgName);
    }
  }
}

export default withCustomizations(withRouter(App));