import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import {SubmitButton, CancelButton} from '../buttons';
import PropTypes from 'prop-types';
import './styles.scss';

class FormContainer extends React.Component {
    getHeader = () => {
        if(this.props.title) {
            return <CardHeader title={this.props.title}></CardHeader>;
        }
    }

    render() {
        return <React.Fragment>
            <Card>
                {this.getHeader()}
                <CardContent>
                    {this.props.children}
                </CardContent>
                <CardActions>
                    <div className="form-container__action-buttons">
                        <SubmitButton className={'fas fa-save'} title={this.props.submitTitle} onClick={this.props.onSubmit} disabled={this.props.submitDisabled} />
                        <CancelButton className={'fas fa-window-close'} title={this.props.cancelTitle} onClick={this.props.onCancel} disabled={false} />
                    </div>
                </CardActions>
            </Card>
        </React.Fragment>
    }
}

FormContainer.propTypes = {
    title: PropTypes.string,
    submitTitle: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
    cancelTitle: PropTypes.string.isRequired,
    onCancel: PropTypes.func,
    submitDisabled: PropTypes.bool
}

export default FormContainer;