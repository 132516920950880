import FormSnackbar from './FormSnackbar';


const variants = {
    success: 'success',
    warning: 'warning',
    error: 'error',
    info: 'info'
}

const anchorOrigin = {
    horizontal: {
        left: 'left',
        center: 'center',
        right: 'right'
    },
    vertical: {
        top: 'top',
        bottom: 'bottom'
    }
}

export {variants, anchorOrigin, FormSnackbar};