import React from "react";
import Switch from '@material-ui/core/Switch';
import grey from '@material-ui/core/colors/grey';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  colorSwitchBase: {
    color: grey[300],
    '&$colorChecked': {
      color: grey[500],
      '& + $colorBar': {
        backgroundColor: grey[500],
      },
    },
  },
  colorBar: {},
  colorChecked: {}
});

class ThemeChanger extends React.Component {
  state = {
    light: false
  };

  render() {
    const { classes } = this.props;

    return <React.Fragment>
      <div className="theme-changer">
        <Switch
          checked={this.state.light}
          onChange={this.handleChange()}
          classes={{
            switchBase: classes.colorSwitchBase,
            checked: classes.colorChecked,
            bar: classes.colorBar,
          }} />
      </div>
    </React.Fragment>
  }

  handleChange = name => event => {
    this.setState({light: event.target.checked});

    if (this.state.light) {
      this.props.onChangeTheme('light');
    } else {
      this.props.onChangeTheme('dark');
    }
  };
}

ThemeChanger.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default  withStyles(styles)(ThemeChanger);