import React from 'react';
import { DotLoader } from 'react-spinners';
import { withTheme } from "@callstack/react-theme-provider";
import { translate } from "react-i18next";

const override = `
    display: block;
`;
 
class PageLoading extends React.Component {
  render() {
    return (
      <div className={'main__page-loading-wrapper--' + this.props.loading}>
        <div className='main__page-loading-wrapper__page-loading-container'>
            <DotLoader
              css={override}
              sizeUnit={"px"}
              size={100}
              color={this.props.theme.spinnerColor}
              loading={this.props.loading} />
              <div className="page-loading-title">{this.props.t('page-loading')}</div>
        </div>
      </div> 
    )
  }
}

export default translate('captions')(withTheme(PageLoading));