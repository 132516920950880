import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

class StyledMessage extends React.Component {
    render() {
        return <React.Fragment>
            <Card>
                <CardHeader title={this.props.title} />
                <CardContent>
                <Typography component="p">
                    {this.props.message}
                    {this.props.children}
                </Typography>
                </CardContent>
            </Card>
        </React.Fragment>
    }
}

StyledMessage.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
}

export default StyledMessage;