import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import {Home, TicketHistory, SolveTicket, TicketMessage, EnvironmentVariables, PrivacyPolicy, TermsOfService} from './pages';

const Routes = () => (

    <div>
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/privacypolicy" component={PrivacyPolicy} />
            <Route exact path="/termsofservice" component={TermsOfService} />
            <Route exact path="/envvars" component={EnvironmentVariables} />
            <Route path="/ticket/:target/:oid/:tid/:ts/history/:mode(view|add)" component={TicketHistory} />
            <Route path="/ticket/:target/:oid/:tid/:ts/solve" component={SolveTicket} />
            <Route path="/ticket/:target/:oid/:tid/:ts/message/:responseKey(policyViewsExceeded|policyHoursExceeded|ticketNotAvailable|ticketAlreadySolved|ticketSolved|server500)" component={TicketMessage} />
            <Redirect to="/" />
        </Switch>
    </div>
);

export default Routes;