import React from 'react';
import PropTypes from 'prop-types';
import './sass/styles.scss';
import { translate } from "react-i18next";
import {withTheme} from '@callstack/react-theme-provider';
import {StyledButton} from '../styled';

class TicketActionButtons extends React.Component {
    handleOnSolve = () => {
        if(!this.props.disableSolve) {
            this.props.onSolve();
        }
    }

    handleOnNeedMoreInfo = () => {
        if(!this.props.disableMoreInfo) {
            this.props.onNeedMoreInfo();
        }
    }

    render() {
        return <React.Fragment>
            <hr />
            <div className="ticket-action-buttons-wrapper">
                <StyledButton
                    variant={this.props.theme.buttonVariant}
                    color={this.props.theme.successColor}
                    className=''
                    unicodeChar='&#128512;&nbsp;'
                    text={this.props.t('action-buttons.marked-solved.title')}
                    onClick={this.handleOnSolve}
                    disabled={this.props.disableSolve}
                    ignoreDisabledBorder={true} />

                <StyledButton
                    variant={this.props.theme.buttonVariant}
                    color={this.props.theme.infoColor}
                    className=''
                    unicodeChar='&#128561;&nbsp;'
                    text={this.props.t('action-buttons.need-more-info.title')}
                    onClick={this.handleOnNeedMoreInfo}
                    disabled={this.props.disableMoreInfo}
                    ignoreDisabledBorder={true} />
            </div>
        </React.Fragment>
    }
}

TicketActionButtons.propTypes = {
    onSolve: PropTypes.func,
    onNeedMoreInfo: PropTypes.func,
    disableSolve: PropTypes.bool.isRequired,
    disableMoreInfo: PropTypes.bool.isRequired
}

export default translate("ticket")(withTheme(TicketActionButtons));