import React from 'react';
import PageLoading from './PageLoading';

const withPageLoading = (WrappedComponent) => {
    return class extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                show: true
            }
        }

        render() {
            return <React.Fragment>
                <PageLoading loading={this.state.show} />
                <WrappedComponent togglePageLoader={this.toggle} {...this.props} />
            </React.Fragment>
        }

        toggle = () => {
            const toggle = !this.state.show;
            this.setState({show: toggle});
        }
    }
}


export {PageLoading, withPageLoading};