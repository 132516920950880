import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';

const styles2 = theme => ({
    margin: {
        margin: theme.spacing.unit,
    },
});

class FormSnackbar extends React.Component {
    render() {
        const { classes, vertical, horizontal } = this.props;

        return <React.Fragment>
            <Snackbar
                anchorOrigin={{
                    vertical,
                    horizontal,
                }}
                open={this.props.open}
                autoHideDuration={this.props.autoHideDuration}
                onClose={this.props.onClose}>                
                
                <MySnackbarContentWrapper
                    variant={this.props.variant}
                    className={classes.margin}
                    message={this.props.message}
                    onClose={this.props.onClose} />

            </Snackbar>
        </React.Fragment>
    }
}

FormSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  autoHideDuration: PropTypes.number.isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
  message: PropTypes.string,
  vertical: PropTypes.oneOf(['top', 'bottom']).isRequired,
  horizontal: PropTypes.oneOf(['left', 'center', 'right']).isRequired,
  onClose: PropTypes.func.isRequired
};

export default withStyles(styles2)(FormSnackbar);


const variantIcon = {
    success: 'far fa-check-circle',
    warning: 'fas fa-exclamation-triangle',
    error: 'fas fa-exclamation-circle',
    info: 'fas fa-info-circle',
  };
  
  const styles1 = theme => ({
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.dark,
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
      width: 'auto'
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing.unit,
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  });
  
  function MySnackbarContent(props) {
    const { classes, className, message, onClose, variant, ...other } = props;
  
    return (
      <SnackbarContent
        className={classNames(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant, variantIcon[props.variant])} />
            {message}
          </span>
        }
        {...other}
      />
    );
  }
  
  MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
  };
  
  const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);