import React from 'react';
import {withPageLoading} from '../components/spinners';
import TicketPortalService from '../services/TicketPortalService.js';
import {responseKeys} from '../services/http';
import CSAT from '../components/CSAT';
import {variants, anchorOrigin, FormSnackbar} from '../components/snackbars';
import { translate } from "react-i18next";
import GAService from '../services/GAService';

class SolveTicket extends React.Component {

    state = {
        snackbar: {
            open: false,
            variant: variants.warning,
            message: ''
        }
    };

    setStateSnackbar = (open, variant, message) => {
        this.setState({snackbar: {open, variant, message}});
    }
    
    handleAnswerClick = async (answer) => {
        const { match: { params } } = this.props;
        await TicketPortalService.addCSATAnswer(answer, params.oid, params.tid, params.ts);
    }

    handleSubmitClick = async (value) => {
        const { match: { params } } = this.props;
        await TicketPortalService.addCSATComment(value, params.oid, params.tid, params.ts);
    }

    handleSnackbarClose = () => {
        this.setStateSnackbar(false, this.state.snackbar.variant, this.state.snackbar.message);
    }

    constructor(props) {
        super(props);

        GAService.pageView(props.location.pathname);
    }
    
    render() {
        return <React.Fragment>
            <CSAT 
                onPositiveClick={this.handleAnswerClick}
                onNegativeClick={this.handleAnswerClick}
                onSubmitClick={this.handleSubmitClick} />
            <FormSnackbar 
                autoHideDuration={5000}
                open={this.state.snackbar.open} 
                variant={this.state.snackbar.variant}
                message={this.state.snackbar.message}
                vertical={anchorOrigin.vertical.top}
                horizontal={anchorOrigin.horizontal.center}
                onClose={this.handleSnackbarClose}/>
        </React.Fragment>
    }

    async componentDidMount() {
        const { match: { params } } = this.props;

        try {
            const response = await TicketPortalService.solve(params.oid, params.tid, params.ts, 'close');
            
            const showCSAT = await TicketPortalService.getShowCSAT(params.oid);

            let redirect = (!showCSAT || response.responseKey !== responseKeys.ticketSolved);

            if (redirect) {
                this.props.history.push(`/ticket/${params.target}/${params.oid}/${params.tid}/${params.ts}/message/${response.responseKey}`);
            } else {
                this.props.togglePageLoader();
                this.setStateSnackbar(true, variants.success, this.props.t(responseKeys.ticketSolvedSnackbar));
            }
        } catch (error) {
            this.props.history.push(`/ticket/${params.target}/${params.oid}/${params.tid}/${params.ts}/message/server500`);
        }
    }
}

export default translate("responseKeys")(withPageLoading(SolveTicket));