import React from 'react';
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule  } from 'react-i18next';
import { I18nextProvider } from "react-i18next";
import format from './format';

export default class I18nextConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      config: i18n
    }
  }

  static getDerivedStateFromProps(props, state) {
      i18n
        // load translation using xhr -> see /public/locales
        // learn more: https://github.com/i18next/i18next-xhr-backend
        .use(Backend)
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)
        // pass the i18n instance to react-i18next.
        .use(reactI18nextModule)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
          initImmediate: false,
          fallbackLng: 'en-US',
          debug: process.env.NODE_ENV === 'development',
          ns: ['ticket', 'formatting', 'responseKeys', 'captions', 'contactInfo'],
          defaultNS: 'ticket',
          backend: {
            loadPath: `${props.configurationsPath}/locales/{{lng}}/{{ns}}.json`,
            crossDomain: true
          },
          interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format
          },
          react: {
            wait: true
          }
      });

      return {config: i18n};
  }

  render() {
    return <I18nextProvider i18n={this.state.config}>{this.props.children}</I18nextProvider>
  }
}