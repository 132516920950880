import React from 'react';
import {withTheme} from '@callstack/react-theme-provider';
import { translate } from "react-i18next";
import { withStyles } from '@material-ui/core/styles';
import {StyledButton, StyledPage} from '../styled'
import PropTypes from 'prop-types';
import validator from 'validator';
import {responseKeys} from '../../services/http';
import './styles.scss';

const styles = {
    icon: {
        'margin-right': '5px;',
        'font-size': '1em'
    }
}

class CSAT extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            disablePositive: false,
            ignoreDisabledBorderPositive: true,
            disableNegative: false,
            ignoreDisabledBorderNegative: true,
            disableSubmit: true,
            disableComment: false,
            commentValue: ''
        }
    }

    render() {
        const hideCSAT = ((this.state.disablePositive || this.state.disableNegative) && this.state.disableComment);

        return <React.Fragment>
            <StyledPage>
                <div className={`csat-container csat-container--${!hideCSAT}`}>
                    <div className="csat-container__title">
                        {this.props.t(`responseKeys:${responseKeys.ticketSolved}`)}
                    </div>
                    <div className="csat-container__title">
                        {this.props.t('csat.question')}
                    </div>

                    <div className="csat-container__answer-buttons">
                        <StyledButton
                            variant={this.props.theme.buttonVariant}
                            color={this.props.theme.successColor}
                            className='far fa-thumbs-up'
                            text={this.props.t('csat.answerKey.positive')}
                            onClick={this.handlePositiveClick}
                            disabled={this.state.disablePositive}
                            ignoreDisabledBorder={this.state.ignoreDisabledBorderPositive}
                            ignoreDisabledColor={true} />

                        <StyledButton
                            variant={this.props.theme.buttonVariant}
                            color={this.props.theme.errorColor}
                            className='far fa-thumbs-down'
                            text={this.props.t('csat.answerKey.negative')}
                            onClick={this.handleNegativeClick}
                            disabled={this.state.disableNegative}
                            ignoreDisabledBorder={this.state.ignoreDisabledBorderNegative}
                            ignoreDisabledColor={true} />
                    </div>

                    <div className="csat-container__comment">
                        <label htmlFor="comment">{this.props.t('csat.commentLabel')}</label>
                        <textarea 
                            name="comment"
                            cols="50" 
                            rows="10" 
                            onChange={this.onCommentChange} disabled={this.state.disableComment}></textarea>
                        <StyledButton
                            variant={this.props.theme.buttonVariant}
                            color={this.props.theme.submitColor}
                            className='fas fa-save'
                            text={this.props.t('csat.submit')}
                            onClick={this.handleSubmitClick}
                            disabled={this.state.disableSubmit}
                            ignoreDisabledBorder={true} />
                    </div>
                </div>

                <div className={`csat-thank-you csat-thank-you--${hideCSAT}`}>
                    <div>{this.props.t('csat.submitConfirm')}</div>
                </div>
            </StyledPage>
        </React.Fragment>
    }

    handlePositiveClick = () => {
        this.setStateAnswers(true, true, false, true);
        this.props.onPositiveClick(true);
    }

    handleNegativeClick = () => {
        this.setStateAnswers(true, true, true, false);
        this.props.onNegativeClick(false);
    }

    handleSubmitClick = () => {
        this.setStateComment(true);
        this.setStateSubmit(true);
        this.props.onSubmitClick(this.state.commentValue);
    }

    setStateAnswers(disablePositive, disableNegative, ignoreDisabledBorderPositive, ignoreDisabledBorderNegative) {
        this.setState({disablePositive, disableNegative, ignoreDisabledBorderPositive, ignoreDisabledBorderNegative});
    }

    setStateSubmit(disableSubmit) {
        this.setState({disableSubmit});
    }

    setStateComment(disableComment) {
        this.setState({disableComment});
    }

    setStateCommentValue(commentValue) {
        this.setState({commentValue});
    }

    onCommentChange = (e) => {
        const value = e.target.value;

        this.setStateCommentValue(value);

        if (validator.isEmpty(value, {ignore_whitespace: true})) {
            this.setStateSubmit(true);
        } else {
            this.setStateSubmit(false);
        }
    }
}

CSAT.propTypes = {
    onPositiveClick: PropTypes.func,
    onNegativeClick: PropTypes.func,
    onSubmitClick: PropTypes.func    
}

export default translate('ticket')(withStyles(styles)(withTheme(CSAT)));