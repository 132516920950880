import React from 'react';
import TicketPortalService from '../services/TicketPortalService.js';
import {withPageLoading} from '../components/spinners';
import {StyledPage} from '../components/styled';
import TicketHeader from '../components/TicketHeader';
import TicketActionButtons from '../components/TicketActionButtons';
import TicketHistoryItem from '../components/TicketHistoryItem';
import AddComment from '../components/AddComment';
import {variants, anchorOrigin, FormSnackbar} from '../components/snackbars';
import { translate } from "react-i18next";
import validator from 'validator'
import GAService from '../services/GAService';

class TicketHistory extends React.Component {
    state = {
        ticket: {
            isValid: false,
            token: '',
            id: '',
            createdOn: '',
            updatedOn: '',
            status: '',
            subject: '',
            comments: []
        },
        comment: {
            open: false,
            value: {
                text: '',
                html: ''
            }
        },
        snackbar: {
            open: false,
            variant: variants.warning,
            message: ''
        }
    };

    setStateSnackbar = (open, variant, message) => {
        this.setState({snackbar: {open, variant, message}});
    }

    setStateComment = (open, value) => {
        this.setState({comment: {open, value}});
    }

    async componentDidMount() {
        const { match: { params } } = this.props;

        try {
            const ticket = await TicketPortalService.getReadReceipts(params.oid, params.tid, params.ts, 'view');
            
            this.setState({
                ticket: {
                    isValid: ticket.isValid,
                    token: ticket.token,
                    id: ticket.message.Id,
                    createdOn: ticket.message.CreatedAt,
                    updatedOn: ticket.message.UpdatedAt,
                    status: ticket.message.Status,
                    subject: ticket.message.Subject,
                    comments: ticket.message.Comments
                }
            });

            if(validator.equals(params.mode.toLowerCase(), 'add') && !validator.equals(ticket.message.Status.toLowerCase(), 'closed')) {
                this.setStateComment(true, {text: '', html: ''});
            }

            this.props.togglePageLoader();
        } catch (error) {
            this.props.history.push(`/ticket/${params.target}/${params.oid}/${params.tid}/${params.ts}/message/server500`);
        }
    }

    constructor(props) {
        super(props);

        GAService.pageView(props.location.pathname);
    }

    render() {
        return <React.Fragment>
            {this.showTicket()}
            <FormSnackbar 
                autoHideDuration={5000}
                open={this.state.snackbar.open} 
                variant={this.state.snackbar.variant}
                message={this.state.snackbar.message}
                vertical={anchorOrigin.vertical.top}
                horizontal={anchorOrigin.horizontal.center}
                onClose={this.handleSnackbarClose}/>
        </React.Fragment>
    }

    showActionButtons = () => {
        const disable = validator.equals(this.state.ticket.status.toLowerCase(), 'closed');

        return <TicketActionButtons 
                    onSolve={this.handleOnSolve} 
                    onNeedMoreInfo={this.handleOnNeedMoreInfo}
                    disableSolve={disable}
                    disableMoreInfo={disable} />
    }

    showTicket = () => {
        if (this.state.ticket.isValid) {
            return <React.Fragment>
                <StyledPage>
                    <TicketHeader 
                        id={this.state.ticket.id}
                        createdOn={this.state.ticket.createdOn}
                        updatedOn={this.state.ticket.updatedOn}
                        status={this.state.ticket.status}
                        subject={this.state.ticket.subject}>
                        
                        {this.showActionButtons()}
                        
                    </TicketHeader>

                    <hr />

                    {this.getAddCommentComponent()}
                    {this.mapCommentsToItems()}

                </StyledPage>
            </React.Fragment>
        }
    }

    getAddCommentComponent = () => {
        if (this.state.comment.open) {
            return <div style={{'marginTop': '10px'}}>
                        <AddComment
                            value={this.state.comment.value.html}
                            onChange={this.handleCommentChange}
                            onSubmit={this.handleAddCommentSubmit}
                            onCancel={this.handleAddCommentClose} />
                    </div>
        } else {
            return null;
        }
    }

    mapCommentsToItems = () => {
        if (this.state.ticket.comments.length === 0) { return []; }

        return this.state.ticket.comments.map((item, index) => {
            return <React.Fragment key={index}>
                <TicketHistoryItem 
                    authorName={item.AuthorName}
                    createdOn={item.CreatedAt}
                    body={item.Body}
                    attachments={item.Attachments}>
                </TicketHistoryItem>
            </React.Fragment>
        });
    }

    handleOnSolve = () => {
        const { match: { params } } = this.props;
        
        this.props.history.push(`/ticket/${params.target}/${params.oid}/${params.tid}/${params.ts}/solve`);
    }

    handleOnNeedMoreInfo = () => {
        //this.setState({comment: {open: true, value: ''}});
        this.setStateComment(true, {text: '', html: ''});
    }

    handleAddCommentClose = () => {
        //this.setState({comment: {open: false, value: ''}});
        this.setStateComment(false, {text: '', html: ''});
    }

    handleAddCommentSubmit = async () => {
        const comment = this.state.comment.value.html;
        
        this.handleAddCommentClose();
        
        this.props.togglePageLoader();

        const { match: { params } } = this.props;
        
        let response;
        
        try {
            response = await TicketPortalService.addComment(comment, params.oid, params.tid, this.state.ticket.token);

            if (response.isValid) {

                const status = (this.state.ticket.status === "pending" || this.state.ticket.status === "solved") ? 'open' : this.state.ticket.status;

                this.setState(prevState => ({
                    ticket: {
                        ...prevState.ticket,
                        token: response.token,
                        status,
                        comments: [{AuthorName: 'You', CreatedAt: (new Date()).toString(), Body: comment}, ...prevState.ticket.comments]
                    }
                }));

                this.props.togglePageLoader();

                this.setStateSnackbar(true, variants.success, this.props.t('commentAdded'));
            } else {
                this.props.togglePageLoader();

                this.setStateSnackbar(true, variants.warning, this.props.t(response.responseKey));
            }
        } catch (ex) {
            this.props.togglePageLoader();
            this.setStateSnackbar(true, variants.error, this.props.t('server500', {message: this.props.t('server500-commentAdded')}));
        }
    }
    
    handleCommentChange = (value) => {
        this.setStateComment(true, value);
    }
    
    handleSnackbarClose = () => {
        this.setStateSnackbar(false, this.state.snackbar.variant, this.state.snackbar.message);
    }
}

export default translate("responseKeys")(withPageLoading(TicketHistory));