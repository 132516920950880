import React from 'react';
import {StyledPage} from '../components/styled';
import {withPageLoading} from '../components/spinners'

class Home extends React.Component {
    render() {
        return <React.Fragment>
            <StyledPage>
                <article>
                    <h1>Welcome to the Ticket Portal</h1>
                </article>
            </StyledPage>
        </React.Fragment>
    }

    componentDidMount() {
        this.props.togglePageLoader();
    }
}

export default withPageLoading(Home);