import http, {responseKeys} from './http';

const sleep = (seconds) => {
    return new Promise(resolove => setTimeout(resolove, 1000 * seconds));
}

export default class TicketPortalService {
    static getReadReceipts = async (orgName, id, timestamp, action, maxAttempts = 3, secondsToSleep = 3) => {
        let response;

        for (let iteration = 1; iteration <= maxAttempts; iteration++) {
            response = await http.get(`${orgName}/${id}/${timestamp}?action=${action}`);

            if (response.data.ResponseKey !== responseKeys.ticketNotAvailable) break;

            if (iteration === maxAttempts) break;

            await sleep(secondsToSleep);
        }

        const isValid = response.status === 200;

        return {
            isValid,
            token: response.data.ContinuationToken,
            message: isValid ? JSON.parse(response.data.Message) : response.data.Message,
            responseKey: response.data.ResponseKey
        };
    }

    static addComment = async (data, orgName, id, token) => {
        const response = await http.postPlainText(`${orgName}/${id}/${token}?action=add`, data);

        return {
            isValid: response.status === 200,
            token: response.data.ContinuationToken,
            message: response.data.Message,
            responseKey: response.data.ResponseKey
        };
    }

    static addCSATAnswer = async (data, orgName, id, timestamp) => {
        const response = await http.postPlainText(`${orgName}/${id}/${timestamp}?action=csatAnswer`, data);

        return {
            isValid: response.status === 200
        };
    }

    static addCSATComment = async (data, orgName, id, timestamp) => {
        const response = await http.postPlainText(`${orgName}/${id}/${timestamp}?action=csatComment`, data);

        return {
            isValid: response.status === 200
        };
    }

    static solve = async (orgName, id, timestamp, action, maxAttempts = 3, secondsToSleep = 3) => {
        let response;

        for (let iteration = 1; iteration <= maxAttempts; iteration++) {
            response = await http.get(`${orgName}/${id}/${timestamp}?action=${action}`);

            if (response.data.ResponseKey !== responseKeys.ticketNotAvailable) break;

            if (iteration === maxAttempts) break;

            await sleep(secondsToSleep);
        }

        const isValid = response.status === 200;

        return {
            isValid,
            token: response.data.ContinuationToken,
            message: response.data.Message,
            responseKey: response.data.ResponseKey
        };
    }

    static getCustomizations = async (orgName) => {
        const response = await http.get(`org/customizations/${orgName}`);
        return response.status === 200 ? JSON.parse(response.data.Message).ConfigPath : response.data.Message;
    }

    static getShowCSAT = async (orgName) => {
        const response = await http.get(`org/customizations/${orgName}`);
        return response.status === 200 ? JSON.parse(response.data.Message).ShowCSat : response.data.Message;
    }

    static getJSON = async (url) => {
        const response = await http.get(url);
        return response.data;
    }
}