import React from 'react';
import {StyledPage} from '../components/styled';
import {Helmet} from "react-helmet";

class TermsOfService extends React.Component {
    render() {
        return <React.Fragment>
            <Helmet>
                <title>Ticker Portal: Terms of Service</title>
                <meta name="description" content="Ticket portal terms of service"></meta>
            </Helmet>
            <StyledPage>
                <article>
                    <section id="intro">
                        <h1>THIS TERMS OF SERVICE (“AGREEMENT”) GOVERNS YOUR ACQUISITION AND USE OF OUR SERVICES.</h1>

                        <p>
                            By using and/or visiting the Web Site and/or linking to any page from the Web Site, you give your assent to both – these Term of Service 
                            &amp; the Privacy Policy, which is incorporated herein by reference.
                        </p>

                        <p>
                            Last Updated: August 8th, 2018
                        </p>

                        <p>
                            Ticket Portal.io is owned by My Read Receipts inc. By accepting this Agreement, either by accessing or using a My Read Receipts, Inc. 
                            (referred to as "My Read Receipts", “TicketPortal.io”, "we", "us", "our", and terms of similar meaning) application ("App") and related services, 
                            or authorizing or permitting Customer (referred to as "any user to access or use the App", "you" on behalf of your entity, agree to be bound by this Agreement.
                        </p>
                    </section>

                    <section id="terms">
                        <ol>
                            <li>
                                ACCESS TO AND USE OF THE SERVICES

                                <ul>
                                    <li>
                                        1.1 During the subscription term with My Read Receipts, you have the limited right to access and use the App consistent with the intended functionalities of the App and plan subscribed to. We will (a) make the App available to you pursuant to this Agreement; (b) provide applicable standard customer support for the App to you at no additional charge; (c) use commercially reasonable efforts to make the App available 24 hours a day, 7 days a week, except (i) unavailability of platform and/or APIs (including but not limited to, Zendesk) (ii) during planned downtime for upgrades and maintenance to the Services; (iii) software installed on your computer or transmissions from your computer to the App; and (iv) for any unavailability caused by circumstances beyond our reasonable control, including, for example, an act of God, act of government, flood, fire, earthquake, civil unrest, act of terror, labor strike, Internet service provider failure or delay, acts undertaken by third parties, including without limitation, denial of service attack ("Force Majeure Event").
                                    </li>
                                    <li>
                                        1.2 In addition to complying with the other terms, conditions and restrictions set forth below in this Agreement, You agree not to (a) license, sublicense, sell, resell, rent, lease, transfer, assign, distribute, or otherwise commercially exploit or make the App available to any third party; (b) modify, adapt, or hack the App or otherwise attempt to gain unauthorized access to related systems or networks; (c) falsely imply any relationship or association with My Read Receipts, (d) use the App in any unlawful manner, including, but not limited to, violation of any person's privacy rights; (e) use the App to send unsolicited or unauthorized mail; (f) use the App to store or transmit files, materials, data, text, audio, video, images or other content that infringes on any person's intellectual property rights; (g) use the Services in any manner that interferes with or disrupts the integrity or performance of the App and its components; (h) attempt to decipher, decompile, reverse engineer or otherwise discover the source code of any software making up the App; (i) use the App to knowingly post, transmit, upload, link to, send or store any content that is unlawful, racist, hateful, abusive, obscene, or discriminatory; (j) use the Services to knowingly post transmit, upload, link to, send or store any viruses, malware, Trojan horses, or any other similar harmful software ("Malicious Software"); (k) use or launch any automated system that accesses the App (i.e., bot) in a manner that sends more request messages to the server in a given period of time than a human can reasonably produce in the same period by using a conventional on-line web browser; or (l) attempt to use, or use the App in violation of this Agreement.
                                    </li>
                                    <li>
                                        1.3 You are responsible for compliance with the provisions of this Agreement by your users and for any and all activities that occur under your account. You are solely responsible for ensuring that use of the App to store and transmit data is compliant with all applicable laws and regulations as well as any and all privacy policies, agreements or other obligations you may maintain.
                                    </li>
                                    <li>
                                        1.4 You agree and acknowledge that each user will be identified by a unique username and password ("Login") and that a user Login may only be used by one (1) individual. You will not share a user Login among multiple individuals. You and your users are responsible for maintaining the confidentiality of all login information for your Account. My Read Receipts reserves the right to periodically verify that your use of the App complies with the Agreement and your service plan. If the use of the App is not in compliance with this Agreement or the service plan, My Read Receipts reserves the right to charge you, and you hereby agree to pay for, said usage.
                                    </li>
                                    <li>
                                        1.5 We reserve the right, in our reasonable discretion, to temporarily suspend your access to and use of the App if we suspect or detect any malicious software connected to your account or use of the App by you and your users.
                                    </li>
                                    <li>
                                        1.6 You acknowledge that My Read Receipts may modify the features and functionality of the App during the subscription term.
                                    </li>
                                    <li>
                                        1.7 You may not access the App if you are a direct competitor of My Read Receipts, except with My Read Receipts’ prior written consent. You may not access the App for the purposes of monitoring performance, availability, functionality, or for any benchmarking or competitive purposes.
                                    </li>
                                    <li>
                                        1.8 If you register for a free trial for any of the App, we will make the App available to you on a trial basis free of charge until the earlier of (a) the end of the free trial period for which you registered to use the App; (b) the start date of any subscription to the App purchased by you for the App; (c) termination of the trial by us in our sole discretion.
                                    </li>
                                    <li>
                                        1.9 From time to time, we may make experimental or "Beta" features available to you at no charge. You may choose to try such Beta features in your sole discretion. Beta features are intended for evaluation purposes and not for production use, are not supported, and may be subject to additional terms that will be presented to you. We may discontinue Beta features at any time in our sole discretion. We will have no liability for any harm or damage arising out of or in connection with a Beta feature.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                CONFIDENTIALITY; SECURITY AND PRIVACY

                                <ul>
                                    <li>
                                        2.1 Subject to the express permissions of this Agreement, each party will protect each other's confidential information from unauthorized use, access or disclosure in the same manner as each protects its own confidential information, but with no less than reasonable care. Except as otherwise expressly permitted pursuant to this Agreement, each party may use each other's confidential information solely to exercise our respective rights and perform our respective obligations under this Agreement and shall disclose such confidential information (a) solely to the employees and/or non-employee service providers and contractors who have a need to know such confidential information for such purposes and who are bound to maintain the confidentiality of, and not misuse, such confidential information; (b) as necessary to comply with an order or subpoena of any administrative agency or court of competent jurisdiction; or (c) as reasonably necessary to comply with any applicable law or regulation.
                                    </li>
                                    <li>
                                        2.2 My Read Receipts will maintain reasonable administrative, physical, and technical safeguards for protection of the security, confidentiality and integrity of data, as described in the Agreement. Those safeguards will include, but will not be limited to, measures for preventing access, use, modification or disclosure of data by personnel except (a) to provide the service of the App and prevent or address service, support or technical problems; (b) as compelled by law.
                                    </li>
                                    <li>
                                        2.3 We may collect personal data, provided by you, such as your name, email address, phone number, credit card information and third-party account credentials (for example, your log-in credentials for third party sites that integrate with the App) of you and your users. We may have access to certain information from a third party social media or authentication service if you log into our App through such service or otherwise provide us with access to information from such service. Any access that we may have to such information from a third party social or authentication service is in accordance with the authorization procedures determined by that service. We neither rent nor sell your personal data in personally identifiable form to anyone.
                                    </li>
                                    <li>
                                        2.4 If you are a Zendesk Partner who is being provided complimentary free service you agree to allow us to use your Zendesk instance for beta testing of new products and services. In addition to that, you agree we have the right to enhance our in house data based on your client data and cross-market our service to your clients in lieu of compensation.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                INTELLECTUAL PROPERTY RIGHTS
                                
                                <p>
                                    Each Party shall retain all rights, title and interest in and to all its respective patents, inventions, copyrights, trademarks, domain names, trade secrets, know-how and any other intellectual property and/or proprietary rights (collectively, "Intellectual Property Rights"). My Read Receipts shall have a fully paid-up, royalty-free, worldwide, transferable, sub-licensable (through multiple layers), assignable, irrevocable and perpetual license to implement, use, modify, commercially exploit, and/or incorporate into the App or otherwise use any suggestions, enhancement requests, recommendations, client data (if you are a Zendesk Partner under section 2.4) or other feedback we receive from you, users, or other third parties acting on your behalf.
                                </p>
                            </li>

                            <li>
                                BILLING, PLAN MODIFICATIONS AND PAYMENTS

                                <ul>
                                    <li>
                                        4.1 All charges associated with your access to and use of the App ("Subscription Charges") are due in full upon commencement of your subscription term. If you fail to pay your subscription charges or other charges indicated on any invoice, we may suspend or terminate access to and use of the App by you and your users.
                                    </li>
                                    <li>
                                        4.2 If you choose to upgrade your service plan or increase the number of users authorized to access and use the App during your subscription term, any incremental subscription charges associated will be prorated over the remaining period of your current subscription term, charged to your account and due and payable upon implementation of such subscription upgrade. In any future subscription term, the incremental subscription charges will be included in your service plan billing unless adjusted prior to the subscription renewal.
                                    </li>
                                    <li>
                                        4.3 No refunds or credits for subscription charges or other fees or payments will be provided to you if you elect to downgrade your service plan. Downgrading your service plan may cause loss of content, features, or capacity of the App as available to you under your account, and My Read Receipts does not accept any liability for such loss.
                                    </li>
                                    <li>
                                        4.4 Unless otherwise stated, our charges do not include any taxes, levies, duties or similar governmental assessments, including value-added, sales, use or withholding taxes assessed by any local, state, provincial or foreign jurisdiction (collectively "Taxes"). You are responsible for paying Taxes, except those assessable against My Read Receipts measured by its net income. We will invoice you for such Taxes if we believe we have a legal obligation to do so and you agree to pay such Taxes if so invoiced.
                                    </li>
                                    <li>
                                        4.5 If you pay by credit card, debit card, or other payment service, you hereby authorize My Read Receipts to bill your credit card or other payment service in advance on a periodic basis in accordance with the terms of the service plan. You agree to promptly update your account information with any changes (for example, a change in your billing address or credit card expiration date) that may occur. My Read Receipts uses a third-party intermediary to manage credit card processing. My Read Receipts does not store, log, or host payment card data.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                CANCELLATION AND TERMINATION

                                <ul>
                                    <li>
                                        5.1 Your subscription to the App will renew for a subscription term equivalent in length to the then expiring subscription term. Either party may elect to terminate your account and subscription to the App by providing notice, in accordance with this Agreement, on or prior to the date thirty (30) days preceding the end of such subscription term. No refunds or credits for subscription charges or other fees or payments will be provided to you if you elect to terminate your subscription to the App or cancel your account prior to the end of your subscription term.
                                    </li>
                                    <li>
                                        5.2 We reserve the right to modify, suspend or terminate the services, your account or your user's rights to access the App, and remove, disable and discard any data if we believe that you or your users have violated this Agreement. Unless legally prohibited from doing so, we will use commercially reasonable efforts to contact you directly via email to notify you when taking any of the foregoing actions. We shall not be liable to you, your users, or any other third party for any such modification, suspension or discontinuation of your rights to access and use the Services. Any suspected fraudulent, abusive, or illegal activity by you, or your users, may be referred to law enforcement authorities at our sole discretion.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                REPRESENTATIONS, WARRANTIES AND DISCLAIMERS

                                <ul>
                                    <li>
                                        6.1 Each Party represents that it has validly entered into this Agreement and has the legal power to do so.
                                    </li>
                                    <li>
                                        6.2 We warrant that during an applicable subscription term (a) this Agreement and the documentation ("Documentation") provided upon request will accurately describe the applicable administrative, physical, and technical safeguards for protection of the security, confidentiality and integrity of data; and (b) the App will perform materially in accordance with the applicable Documentation.
                                    </li>
                                    <li>
                                        6.3 EXCEPT AS SPECIFICALLY SET FORTH IN SECTION 6.2, THE SITES AND THE SERVICES, INCLUDING ALL SERVER AND NETWORK COMPONENTS ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND TO THE FULLEST EXTENT PERMITTED BY LAW, AND WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. YOU ACKNOWLEDGE THAT WE DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR FREE FROM VIRUSES OR OTHER MALICIOUS SOFTWARE, AND NO INFORMATION OR ADVICE OBTAINED BY YOU FROM US OR THROUGH THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                LIMITATION OF LIABILITY

                                <ul>
                                    <li>
                                        7.1 UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN CONTRACT, TORT, NEGLIGENCE OR OTHERWISE) WILL EITHER PARTY TO THIS AGREEMENT, OR THEIR AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, SUPPLIERS OR LICENSORS BE LIABLE TO THE OTHER PARTY OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST SALES OR BUSINESS, LOST DATA, (BEING DATA LOST IN THE COURSE OF TRANSMISSION VIA YOUR SYSTEMS OR OVER THE INTERNET THROUGH NO FAULT OF My Read Receipts), BUSINESS INTERRUPTION, LOSS OF GOODWILL, OR FOR ANY TYPE OF INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE LOSS OR DAMAGES, OR ANY OTHER LOSS OR DAMAGES INCURRED BY THE OTHER PARTY OR ANY THIRD PARTY IN CONNECTION WITH THIS AGREEMENT, THE SERVICES OR CONSULTING SERVICES, REGARDLESS OF WHETHER SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF OR COULD HAVE FORESEEN SUCH DAMAGES.
                                    </li>
                                    <li>
                                        7.2 NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT, My Read Receipts' AGGREGATE LIABILITY TO YOU OR ANY THIRD PARTY ARISING OUT OF THIS AGREEMENT, THE APP SERVICES OR CONSULTING SERVICES, SHALL IN NO EVENT EXCEED THE SUBSCRIPTION CHARGES AND/OR CONSULTING FEES PAID BY YOU DURING THE TWELVE (12) MONTHS PRIOR TO THE FIRST EVENT OR OCCURRENCE GIVING RISE TO SUCH LIABILITY.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                INDEMNIFICATION

                                <ul>
                                    <li>
                                        8.1 We will indemnify and hold you harmless, from and against any claim against you by reason of your use of the App as permitted hereunder, brought by a third party alleging that such App infringes or misappropriates a third party's valid patent, copyright, trademark or trade secret (an "IP Claim"). We shall, at our expense, defend such IP Claim, provided that (a) you promptly notify My Read Receipts of the threat or notice of such IP Claim; (b) We will have the sole and exclusive control and authority to select defense attorneys, and defend and/or settle any such IP Claim; and (c) You fully cooperate with My Read Receipts in connection therewith. If use of the App by you, or your users has become the subject of any such IP Claim, we may, at our option and expense, (a) procure for you the right to continue using the App as set forth hereunder; (b) replace or modify the App to make it non-infringing; or (c) if options (a) or (b) are not commercially reasonable or practicable as determined by My Read Receipts, terminate your subscription to the App subscription and repay you, on a pro-rata basis, any subscription charges previously paid to My Read Receipts for the corresponding unused portion of your subscription term for such service. We will have no liability or obligation under this Section 8.1 with respect to any IP Claim if such claim is caused in whole or in part by (i) compliance with designs, data, instructions or specifications provided by you; (ii) modification of the App by anyone other than My Read Receipts; or (iii) the combination, operation or use of the App with other hardware or software where a service would not by itself be infringing. The provisions of this Section 8.1 state the sole, exclusive and entire liability of My Read Receipts to you and constitute your sole remedy with respect to an IP Claim brought by reason of access to or use of the App by you, or your users.
                                    </li>
                                    <li>
                                        8.2 You will indemnify and hold My Read Receipts, its directors, officers, employees and agents, harmless against any claim brought by a third party against My Read Receipts arising from or related to use of the App by you, or your users in breach of this Agreement or matters for which you have expressly agreed to be responsible pursuant to this Agreement; provided (a) We shall promptly notify you of the threat or notice of such claim; (b) You will have the sole and exclusive control and authority to select defense attorneys, and defend and/or settle any such claim (however, you shall not settle or compromise any claim that results in liability or admission of any liability by us without our prior written consent); and (c) We fully cooperate with you in connection therewith.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                ASSIGNMENT, ENTIRE AGREEMENT AND AMENDMENT

                                <ul>
                                    <li>
                                        9.1 You may not, directly or indirectly, by operation of law or otherwise, assign all or any part of this Agreement or your rights under this Agreement without our prior consent, which consent will not be unreasonably withheld.
                                    </li>
                                    <li>
                                        9.2 This Agreement, together with any executed Supplemental Term(s), shall constitute the entire agreement, and supersede any and all prior agreements between you and My Read Receipts with regard to the subject matter hereof. There are no oral promises, conditions, representations, understandings, interpretations, or terms of any kind between the parties, except as may otherwise be expressly provided herein.
                                    </li>
                                    <li>
                                        9.3 We may amend this Agreement from time to time, in which case the new Agreement will supersede prior versions. Our failure to enforce at any time any provision of this Agreement does not constitute a waiver of that provision or of any other provision of this Agreement.
                                    </li>
                                </ul>
                            </li>

                            <li>
                                SEVERABILITY

                                <p>
                                    If any provision in this Agreement is held by a court of competent jurisdiction to be unenforceable, such provision shall be modified by the court and interpreted so as to best accomplish the original provision to the fullest extent permitted by law, and the remaining provisions of this Agreement shall remain in effect.
                                </p>
                            </li>

                            <li>
                                EXPORT COMPLIANCE AND USE RESTRICTIONS

                                <p>
                                    The App and services that we provide- to you and your users may be subject to U.S. export control and economic sanctions laws. You agree to comply with all such laws and regulations as they relate to access to and use of the App. You shall comply with all applicable laws regarding the transmission of technical data exported from the United States and the country in which you and/or your users are located.
                                </p>
                            </li>

                            <li>
                                RELATIONSHIP OF THE PARTIES

                                <p>
                                    The parties are independent contractors. This Agreement does not create a partnership, franchise, joint venture, agency, fiduciary or employment relationship among the parties.
                                </p>
                            </li>

                            <li>
                                GOVERNING LAW

                                <p>
                                    All transactions and services with Company shall be deemed to occur in the State of Florida and be regulated thereby, regardless of where you may reside, be situated or access Our Website. The Transactions, services and all claims or causes of actions shall be governed, construed and enforced in accordance with Florida law and applicable federal law, in accordance with the laws of the State of Florida without reference to or application of Florida's conflict of law principles.
                                </p>
                            </li>

                            <li>
                                CHOICE OF FORUM

                                <p>
                                    IF ANY CLAIM, ACTION OR LAWSUIT ARISES BETWEEN YOU AND THE COMPANY, YOU EXPRESSLY CONSENT AND SUBMIT TO THE EXCLUSIVE JURISDICTION AND VENUE OF EITHER THE STATE OR FEDERAL COURTS LOCATED IN PALM BEACH COUNTY, FLORIDA and you expressly agree that any such Court has personal jurisdiction over you and your company. You waive all defenses of lack of personal jurisdiction and forum non-conveniens.
                                </p>
                            </li>

                            <li>
                                RIGHT TO INJUNCTIVE RELIEF

                                <p>
                                    You acknowledge that the terms of Articles 1, 2, and 3 of this Agreement are reasonably necessary to protect the legitimate interests of the Company, are reasonable in scope and duration, and are not unduly restrictive. You further acknowledge that a breach of any of the terms of Articles 1, 2, or 3 of this Agreement will render irreparable harm to the Company, and that a remedy at law for breach of the Agreement is inadequate, and that the Company shall therefore be entitled to seek any and all equitable relief, including, but not limited to, injunctive relief, and to any other remedy that may be available under any applicable law or agreement between the parties. You acknowledge that an award of damages to the Company does not preclude a court from ordering injunctive relief. Both damages and injunctive relief shall be proper modes of relief and are not to be considered as alternative remedies.
                                </p>
                            </li>

                            <li>
                                NOTICES

                                <p>
                                    All notices to be provided by My Read Receipts to you under this Agreement may be delivered in writing (a) by physical mail to the contact mailing address provided by you on any order form; or (b) email to the email address provided for your account owner. You must give notice to My Read Receipts in writing by certified mail to 3365 S Federal Hwy, Unit D, Boynton Beach, FL 33435 U.S.A. Attn: Legal. 
                                </p>

                                <p>
                                    Company reserves the right to modify, alter or update these terms at any time. Such modifications shall be effective immediately upon posting. By continuing to use Our Website after we have posted such modifications or updates, you agree to be bound by the terms as revised.
                                </p>
                            </li>
                        </ol>
                    </section>
                </article>
            </StyledPage>
        </React.Fragment>
    }
}

export default TermsOfService;