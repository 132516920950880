import React from "react";
import styled from "styled-components";
import { withTheme } from "@callstack/react-theme-provider";
import {Link} from "react-router-dom";

const StyledLink = ({to, text, color, colorHover}) => (
    <Link to={to}><Span color={color} colorHover={colorHover}>{text}</Span></Link>
);

const Span = styled.span`
    color: ${props => props.color};
    &:hover {
        color: ${props => props.colorHover};
    }
`;

export default withTheme(StyledLink);