import React from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    root: {
        'padding': '10px',
        'height': '99%'
    },
  };

class StyledPage extends React.Component {
    render() {
        const { classes, children, className } = this.props;

        return <React.Fragment>
            <Paper className={classNames(classes.root, className)}>
                {children }
            </Paper>
        </React.Fragment>
    }
}

StyledPage.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string
}

export default withStyles(styles)(StyledPage);