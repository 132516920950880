import React from "react";
import styled from "styled-components";
import { withTheme } from "@callstack/react-theme-provider";
import { translate } from "react-i18next";
import ReactHtmlParser from 'react-html-parser';
import {StyledLink} from '../../components/styled';

class Footer extends React.Component {
    shouldComponentUpdate() {
        return false;
    }

    render() {
        const props = this.props;

       return <Container 
                    backgroundColor={props.theme.footerBackgroundColor} 
                    backgroundColorGradient={props.theme.footerBackgroundColorGradient} 
                    foreColor={props.theme.footerForeColor}>
                    <div className="footer-container">
                        <div className="footer-container__links">
                            <ul>
                                <li className={'link--' + props.t('links.home.visible')}>
                                    <StyledLink 
                                        color={props.theme.footerForeColor} 
                                        colorHover={props.theme.footerForeColor} 
                                        to="/" 
                                        text={props.t('links.home.text')} />
                                </li>
                                <li className={'link--' + props.t('links.installation.visible')}>
                                    <Hyperlink 
                                        color={props.theme.footerForeColor} 
                                        colorHover={props.theme.footerForeColor} 
                                        href={props.t('links.installation.href')} 
                                        target={props.t('links.installation.target')}>
                                        
                                        {props.t('links.installation.text')}
                                    </Hyperlink>
                                </li>
                                <li className={'link--' + props.t('links.support.visible')}>
                                    <Hyperlink 
                                        color={props.theme.footerForeColor} 
                                        colorHover={props.theme.footerForeColor} 
                                        href={props.t('links.support.href')} 
                                        target={props.t('links.support.target')}>
                                        
                                        {props.t('links.support.text')}
                                    </Hyperlink>
                                </li>
                                <li className={'link--' + props.t('links.contactUs.visible')}>
                                    <Hyperlink 
                                        color={props.theme.footerForeColor} 
                                        colorHover={props.theme.footerForeColor} 
                                        href={props.t('links.contactUs.href')} 
                                        target={props.t('links.contactUs.target')}>
                                        
                                        {props.t('links.contactUs.text')}
                                    </Hyperlink>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-container__social">
                            <ul>
                                <li className={'link--' + props.t('socialMedia.twitter.visible')}>
                                    <Hyperlink 
                                        color={props.theme.footerForeColor} 
                                        colorHover={props.theme.footerForeColorHover} 
                                        href={'https://twitter.com/' + props.t('socialMedia.twitter.screenName')}
                                        target={props.t('socialMedia.twitter.target')}>

                                        <i className="fab fa-twitter"></i>
                                    </Hyperlink>
                                </li>

                                <li className={'link--' + props.t('socialMedia.facebook.visible')}>
                                    <Hyperlink 
                                        className="social-link-md"
                                        color={props.theme.footerForeColor} 
                                        colorHover={props.theme.footerForeColorHover} 
                                        href={'https://www.facebook.com/' + props.t('socialMedia.facebook.profileName')}
                                        target={props.t('socialMedia.facebook.target')}>
                                        
                                        <i className="fab fa-facebook-f"></i>
                                    </Hyperlink>

                                    <Hyperlink 
                                        className="social-link-sm"
                                        color={props.theme.footerForeColor} 
                                        colorHover={props.theme.footerForeColorHover} 
                                        href={'fb://profile/' + props.t('socialMedia.facebook.id')}
                                        target={props.t('socialMedia.facebook.target')}>
                                        
                                        <i className="fab fa-facebook-f"></i>
                                    </Hyperlink>
                                </li>

                                <li className={'link--' + props.t('socialMedia.linkedin.visible')}>
                                    <Hyperlink 
                                        className="social-link-md"
                                        color={props.theme.footerForeColor} 
                                        colorHover={props.theme.footerForeColorHover} 
                                        href={'https://www.linkedin.com/company/' + props.t('socialMedia.linkedin.profileName')}
                                        target={props.t('socialMedia.linkedin.target')}>
                                        
                                        <i className="fab fa-linkedin-in"></i>
                                    </Hyperlink>

                                    <Hyperlink 
                                        className="social-link-sm"
                                        color={props.theme.footerForeColor} 
                                        colorHover={props.theme.footerForeColorHover} 
                                        href={'linkedin://company/' + props.t('socialMedia.linkedin.profileName')}
                                        target={props.t('socialMedia.linkedin.target')}>
                                        
                                        <i className="fab fa-linkedin-in"></i>
                                    </Hyperlink>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-container__contact">
                            <p>{props.t('address.line1')}</p>
                            <p>{props.t('address.line2')}</p>
                            <p>{props.t('address.line3')}</p>

                            <ul>
                                <li className={'link--' + props.t('links.privacyPolicy.visible')}>
                                    <StyledLink 
                                        color={props.theme.footerForeColor} 
                                        colorHover={props.theme.footerForeColor} 
                                        to={props.t('links.privacyPolicy.href')} 
                                        text={props.t('links.privacyPolicy.text')} />
                                </li>
                                <li className={'link--' + props.t('links.termsOfService.visible')}>
                                    <StyledLink 
                                        color={props.theme.footerForeColor} 
                                        colorHover={props.theme.footerForeColor} 
                                        to={props.t('links.termsOfService.href')} 
                                        text={props.t('links.termsOfService.text')} />
                                </li>
                            </ul>
                        </div>
                        <Copyright borderColor={props.theme.footerCopyrightBorderColor} className="footer-container__copyright">
                            {ReactHtmlParser(props.t('copyrightInfo'))}
                        </Copyright>
                    </div>
                </Container>
    }
}

const Container = styled.footer`
background: ${props => `linear-gradient(to right, ${props.backgroundColor}, ${props.backgroundColorGradient});`};
    color: ${props => props.foreColor};
`;

const Hyperlink = styled.a`
    color: ${props => props.color};
    &:hover {
        color: ${props => props.colorHover};
    }
`;

const Copyright = styled.div`
    border-top: 1px solid ${props => props.borderColor};
    padding-top: 10px;
`;

export default translate('contactInfo')(withTheme(Footer));