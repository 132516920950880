import axios from 'axios';

const _composeHeaders = (token) => {
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
    };
};

const _composeConfig = (token, params) => {
    return {
        headers: _composeHeaders(''),
        mode: 'cors',
        cache: 'no-cache',
        params
    };
}

export default class http {
    static instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    });

    static get = async (route) => {
        return http.instance.get(route, _composeHeaders(''));
    }

    static post = async (route, data) => {
        return http.instance.post(route, data, _composeHeaders(''));
    }

    static postPlainText = async (route, data) => {
        return http.instance.post(route, data, {headers: { 'Content-Type': 'text/plain' }});
    }

    static put = async (route, data) => {
        return http.instance.put(route, data, _composeHeaders(''));
    }

    static patch = async (route, data) => {
        return http.instance.patch(route, data, _composeHeaders(''));
    }

    static delete = async(route, params) => {
        return http.instance.delete(route, _composeConfig('', params));
    }
}

export const responseKeys =  {
    unknown:  'unknown',
    policyViewsExceeded:  'policyViewsExceeded',
    policyHoursExceeded:  'policyHoursExceeded',
    ticketNotAvailable:  'ticketNotAvailable',
    ticketAlreadySolved:  'ticketAlreadySolved',
    unableToSolve:  'unableToSolve',
    badRequest:  'badRequest',
    commentAdded:  'commentAdded',
    ticketSolved:  'ticketSolved',
    ticketSolvedSnackbar: 'ticketSolvedSnackbar',
    orgNotUsingPortal:  'orgNotUsingPortal',
    pixelDoesNotExistForTicket:  'pixelDoesNotExistForTicket',
    ticketViewed:  'ticketViewed',
    orgCustomizationsViewed:  'orgCustomizationsViewed'
}