import React from 'react';
import {withTheme} from '@callstack/react-theme-provider';
import { translate } from "react-i18next";
import styled from "styled-components";
import PropTypes from 'prop-types';
import './sass/styles.scss';
import ReactHtmlParser from 'react-html-parser';

class TicketHistoryItem extends React.Component {

    getTitle = (fontColor) => {
        return <Title 
                    className="ticket-history-container__title"
                    fontColor={fontColor}>
                    {this.props.authorName}
                </Title>;
    }

    getSubTitle = (fontColor) => {
        return <Title
                    className="ticket-history-container__sub-title"
                    fontColor={fontColor}>
                    {this.props.t("long-abbr", { date: new Date(this.props.createdOn)})}
                </Title>;
    }

    getBody = (fontColor) => {
        return <Body fontColor={fontColor} className="ticket-history-container__body">
                    {ReactHtmlParser(this.props.body)}
                </Body>;
    }

    getComment = () => {
        if (this.props.authorName.toLowerCase() === 'agent') {
            return <Wrapper 
                        className="ticket-history-container ticket-history-container--agent"
                        backgroundColor={this.props.theme.ticketHistoryItemAgent.backgroundColor}
                        borderColor={this.props.theme.ticketHistoryItemAgent.borderColor}>

                        {this.getTitle(this.props.theme.ticketHistoryItemAgent.fontColor)}

                        {this.getSubTitle(this.props.theme.ticketHistoryItemAgent.fontColor)}

                        {this.getBody(this.props.theme.ticketHistoryItemAgent.fontColor)}
                    </Wrapper>;
        } else {
            return <Wrapper 
                        className="ticket-history-container ticket-history-container--customer"
                        backgroundColor={this.props.theme.ticketHistoryItemCustomer.backgroundColor}
                        borderColor={this.props.theme.ticketHistoryItemCustomer.borderColor}>

                        {this.getTitle(this.props.theme.ticketHistoryItemCustomer.fontColor)}

                        {this.getSubTitle(this.props.theme.ticketHistoryItemCustomer.fontColor)}

                        {this.getBody(this.props.theme.ticketHistoryItemCustomer.fontColor)}
                    </Wrapper>;
        }
    }

    render() {
        return <React.Fragment>
            {this.getComment()}
        </React.Fragment>
    }
}

const Wrapper = styled.div`
    border-color: ${props => props.borderColor};
    background-color: ${props => props.backgroundColor};
`;

const Body = styled.div`
    color: ${props => props.fontColor}
`;

const Title = styled.div`
    color: ${props => props.fontColor};
`;

TicketHistoryItem.propTypes = {
    authorName: PropTypes.string.isRequired,
    createdOn: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    attachments: PropTypes.array
}

export default translate("formatting")(withTheme(TicketHistoryItem));