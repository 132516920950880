import React from 'react';
import {StyledPage, StyledMessage, StyledLink} from '../components/styled';
import { translate } from "react-i18next";
import GAService from '../services/GAService';

class TicketMessage extends React.Component {
    constructor(props) {
        super(props);

        GAService.pageView(props.location.pathname);
    }

    render() {
        const { match: { params } } = this.props;

        return <React.Fragment>
            <StyledPage>
                <StyledMessage title={this.props.t('ticket:message', {id: params.tid})} message={this.props.t(params.responseKey)}>
                    <StyledLink 
                        to={`/ticket/${params.target}/${params.oid}/${params.tid}/${params.ts}/history/view`} 
                        text={this.props.t('ticket:viewTicketLink')} />
                </StyledMessage>
            </StyledPage>
        </React.Fragment>
    }
}

export default translate("responseKeys")(TicketMessage);
