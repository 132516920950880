import React from 'react';
import {withTheme} from '@callstack/react-theme-provider';
import PropTypes from 'prop-types';
import './sass/styles.scss';
import styled from "styled-components";
import { translate } from "react-i18next";

class TicketHeader extends React.Component {
    render() {
        return <React.Fragment>
            <section>
                <Title className="ticket-header-wrapper__title" color={this.props.theme.ticketHeader.titleColor}>
                    {this.props.subject}
                    &nbsp;(#{this.props.id})
                </Title>

                <hr />

                <div className="ticket-header-wrapper__field-group">
                    <div>
                        <label>{this.props.t("header.status-label")}</label>
                    </div>
                    <div className="init-cap">{this.props.status}</div>
                </div>

                <div className="ticket-header-wrapper__field-group">
                    <div>
                        <label>{this.props.t("header.createdOn-label")}</label>
                    </div>
                    <div>
                    {   this.props.t("formatting:long-abbr", {date: new Date(this.props.createdOn)})}
                    </div>
                </div>

                <div className="ticket-header-wrapper__field-group">
                    <div>
                        <label>{this.props.t("header.updated-label")}</label>
                    </div>
                    <div>
                        {this.props.t("formatting:long-abbr", {date: new Date(this.props.updatedOn)})}
                    </div>
                </div>
                <div>{this.props.children}</div>
            </section>
        </React.Fragment>
    }
}

const Title = styled.h1`
    color: ${props => props.color};
`;

TicketHeader.propTypes = {
    theme: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    createdOn: PropTypes.string.isRequired,
    updatedOn: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired
}

export default translate("ticket")(withTheme(TicketHeader));