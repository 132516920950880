import 'babel-polyfill';
import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import createHistory from 'history/createBrowserHistory';
import App from './App';
import { hydrate, render } from "react-dom";
import * as serviceWorker from './serviceWorker';
import Routes from './Routes';
import '@fortawesome/fontawesome-free/css/all.css'
import './sass/styles.scss';

const rootElement = document.getElementById("root");
const history = createHistory();

if (rootElement.hasChildNodes()) {
  hydrate(<React.Fragment>
            <Router basename="/" browserHistory={history}>
              <App>
                <Routes />
              </App>
            </Router>
          </React.Fragment>, rootElement);
} else {
  render(<React.Fragment>
          <Router basename="/" browserHistory={history}>
            <App>
              <Routes />
            </App>
          </Router>
        </React.Fragment>, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();