import React from 'react';
import { PropTypes } from 'prop-types'
import './styles.scss';

export default class RichTextEditor extends React.Component {
    handleOnChange = (e) => {
        this.props.onChange({text: e.target.value, html: e.target.value});
    }

    render() {
        return <React.Fragment>
            <textarea className="text-area" rows="5" onChange={this.handleOnChange} value={this.props.value}></textarea>
        </React.Fragment>
    }
}

RichTextEditor.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
}