import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styled from "styled-components";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const styles = {
    icon: {
        'margin-right': '5px;',
        'font-size': '1em'
    }
}

const StyledButton = (props) => {
    const color = !props.disabled || props.ignoreDisabledColor ? props.color : 'inherit';
    const borderColor = (props.disabled && !props.ignoreDisabledBorder) ? props.color : 'inherit';

    const handleClick = () => {
        if (!props.disabled) {
            props.onClick();
        }
    }

    const getIcon = () => {
        if (props.className) {
            return <i className={props.className} />
        } else if (props.unicodeChar) {
            return <span className={props.className} role="img" aria-label="">{props.unicodeChar}</span>
        }

        return null;
    }

    return <Wrapper borderColor={borderColor} onClick={handleClick}>
        <Button variant={props.variant} disabled={props.disabled}>
            <ButtonContent color={color}>
                {getIcon()}
                {props.text}
            </ButtonContent>
        </Button>
    </Wrapper>
}

const ButtonContent = styled.div`
    color: ${props => props.color};
    font-weight: bold;

    i {
        padding-right: 5px;
    }
`;

const Wrapper = styled.div`
    border: 0.2rem solid ${props => props.borderColor};
    padding: 3px;
`;

StyledButton.propTypes = {
    variant: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool.isRequired,
    ignoreDisabledBorder: PropTypes.bool.isRequired,
    ignoreDisabledColor: PropTypes.bool
}

StyledButton.defaultProps = {
    ignoreDisabledColor: false
}

export default withStyles(styles)(StyledButton);